@import '../node_modules/@basic-fit/design-system/dist/styles.css';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

@font-face {
    font-family: 'Argumentum';
    font-style: normal;
    src: url('fonts/ArgumentumLight.ttf');
    src: url('fonts/ArgumentumLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Impact';
    font-style: normal;
    src: url('fonts/impact.ttf');
    src: url('fonts/impact.ttf') format('truetype');
}

@font-face {
    font-family: 'ArgumentumMedium';
    font-style: normal;
    src: url('fonts/ArgumentumMedium.ttf');
    src: url('fonts/ArgumentumMedium.ttf') format('truetype');
}

@font-face {
    font-family: 'ArgumentumUltraLight';
    font-style: normal;
    src: url('fonts/ArgumentumUltraLight.ttf');
    src: url('fonts/ArgumentumUltraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'ArgumentumLight';
    font-style: normal;
    src: url('fonts/ArgumentumLight.ttf');
    src: url('fonts/ArgumentumLight.ttf') format('truetype');
}

input {
    height: 100%;
    font-size: 14px;
    border-width: 0;
}

input[type='text']:focus {
    outline: 0px solid #fff;
}

input[type='password']:focus {
    outline: 0px solid #fff;
}

input.text-field-normal {
    font-family: Argumentum;
    margin-left: 12px;
    padding-top: 1px;
    position: absolute;
    height: 26px;
}

#ie-incompatible-message {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0 auto;
    width: 100%;
    padding: 20px;
    padding-right: 25px;
    padding-left: 25px;
    margin-top: 10px;
    background-color: #fff;
    line-height: 30px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.hidden {
    display: none;
}

.shown {
    display: none;
}

/* general */

.app {
    max-width: 1536px;
    max-height: 100%;
    margin: auto;
}

.noScroll {
    overflow: hidden;
}

.small-btn:active {
    background: #52188f;
    box-shadow: inset 0px 2px 0px #000000;
}

.show-more-line-box {
    text-align: center;
}

.show-more-line {
    color: #1292d8;
    font-family: ArgumentumMedium;
    text-align: center;
    cursor: pointer;
    margin-top: 13px;
    /* background: red; */
    /* width: 100%; */
    /* display: inline-block; */
    /* margin-bottom: 13px; */
    text-align: center;
}

/* show more */

.show-more-arrow {
    margin-left: 10px;
    vertical-align: middle;
}

/* flip 180 anim */

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(180deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(180deg);
        height: 220px;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

.open {
    animation-name: spin;
    animation-duration: 0.2s;
    transform: rotate(180deg);
}

ul {
    list-style: none;
}

@media only screen and (max-width: 290px) {
    input.text-field-normal {
        width: 80%;
    }
}

@media (min-width: 291px) and (max-width: 320px) {
    input.text-field-normal {
        width: 83%;
    }
}

@media (min-width: 321px) {
    input.text-field-normal {
        width: 85%;
    }
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Argumentum', -apple-system, sans-serif;
    background: #f5f5fe;
    /* position: relative; */
    /* min-height: 100vh; */
}

a {
    color: #333;
    text-decoration: none;
}

input {
    height: 100%;
    font-size: 14px;
    border-width: 0;
}

input[type='text']:focus {
    outline: 0px solid #fff;
}

input[type='password']:focus {
    outline: 0px solid #fff;
}

input.text-field-normal {
    font-family: Argumentum;
    margin-left: 12px;
    padding-top: 1px;
    position: absolute;
    height: 26px;
}

.app {
    max-width: 1536px;
    max-height: 100%;
    margin: auto;
}

.loader-float-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(173, 173, 208, 0.72);
    z-index: 5;
    padding: 16px;
    padding-right: 45px;
    padding-left: 45px;
    /* padding-top: 80px; */
}

/* loader */

.float-loader {
    text-align: center;
    margin: auto;
    z-index: 1;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
    height: 30%;
    max-width: 420px;
    min-width: 240px;
    max-height: 220px;
    min-height: 175px;
    width: 100%;
    position: absolute;
    top: 30%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

@media (max-width: 518px) {
    .float-loader {
        width: 78%;
    }
}

.float-loader > img {
    width: 70%;
    margin-top: 35px;
    max-width: 280px;
}

.no-margin-top.under-loader-text {
    /* position: absolute; */
    font-family: ArgumentumMedium;
    color: #fe7000;
    height: 30px;
    font-size: 20px;
    top: 0px;
}

@media (max-width: 400px) {
    .under-loader-text {
        margin-top: -3%;
    }
}

@media (max-width: 340px) {
    .under-loader-text {
        margin-top: 0%;
    }
}

.under-loader-text.with-thump {
    margin-top: -40px;
}

.under-loader-text.under-loader-text-gap {
    margin-top: -15px;
}

.just-fade-in {
    -webkit-animation: fadein 0.4s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.4s;
    /* Firefox < 16 */
    -ms-animation: fadein 0.4s;
    /* Internet Explorer */
    -o-animation: fadein 0.4s;
    /* Opera < 12.1 */
    animation: fadein 0.4s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

ul {
    list-style: none;
}

@media only screen and (max-width: 290px) {
    input.text-field-normal {
        width: 80%;
    }
}

@media (min-width: 291px) and (max-width: 320px) {
    input.text-field-normal {
        width: 83%;
    }
}

@media (min-width: 321px) {
    input.text-field-normal {
        width: 85%;
    }
}

.compensation-box {
    padding: 19px;
    /* margin-top: 30px; */
    overflow: auto;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(115, 34, 201, 0.3);
    margin: auto;
}

.tick-mark-box {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 20%;
    text-align: center;
}

.compensation-text {
    font-family: Impact;
    text-transform: uppercase;
    line-height: 22px;
    float: right;
    width: 73%;
}

input:focus {
    border: 1px solid #1292d8;
}

.graph > img {
    width: 100%;
}

/* Hide password reveal button in MS Edge */
::-ms-reveal {
    display: none !important;
}

/* Hide autofill Safari icon */
::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
}

.access-change-list .list-decimal::marker {
    font-size: 1rem;
    font-weight: bold;
    color: #fe7000;
}

@media (max-width: theme(screens.md.min)) {
    h3 {
        @apply text-h4 !important;
    }

    h4 {
        @apply text-h5 !important;
    }

    h5 {
        @apply text-h6 !important;
    }
}

@media (max-width: theme(screens.sm.min)) {
    h3 {
        @apply text-h6 !important;
    }

    h4 {
        @apply text-h7 !important;
    }

    h5 {
        @apply text-h8 !important;
    }

    h6 {
        @apply text-h8 !important;
    }
}
