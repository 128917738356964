@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-display: swap;
    font-family: 'Heading Pro Double';
    font-weight: 400;
    src: url('../../fonts/Heading-Pro-Double-Regular.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Heading Pro Treble';
    font-weight: 400;
    src: url('../../fonts/Heading-Pro-Treble-Regular.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Heading Pro Treble';
    font-weight: 700;
    src: url('../../fonts/Heading-Pro-Treble-ExtraBold.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Heading Pro Treble';
    font-weight: 900;
    src: url('../../fonts/Heading-Pro-Treble-Heavy.woff2') format('woff2');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
}

html {
    height: 100vh;
    min-height: 100vh;
    min-height: stretch;
}

#root {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
}

[data-cy='collapse'] a {
    @apply text-blue;
    text-decoration: underline;
}

/* Hide arrows from input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input[type='date']::-webkit-calendar-picker-indicator {
    transform: translateY(-7px);
}

.bf-membership-change-content {
    font-family: 'Heading Pro Treble';
    font-size: 16px;
    line-height: 21px;
}

.bf-membership-change-content ul {
    display: inline-block;
}

.bf-membership-change-content ul > li {
    list-style: none;
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    background-position: left top;
    background-repeat: no-repeat;
    background-position-y: -2px;
    background-size: 24px;
}

.bf-membership-change-content ul > .cross {
    background-size: 16px;
    background-position-y: 2px;
    background-position-x: 3px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 10.9994L6 6.00017L11 1.00034M11 11L1 1.00034' stroke='%23EE3444' stroke-width='2'/%3E%3C/svg%3E");
}

.bf-membership-change-content ul > .tick,
.bf-membership-change-content ul > .green,
.bf-membership-change-content ul > .blue {
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='3 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00049 10.9967L9.54803 15.3568M17.8497 6.68945L8.65734 15.8818' stroke='%23FE7000' stroke-width='3'/%3E%3C/svg%3E%0A");
}

.bf-membership-change-content ul > .dash {
    background-position-y: 7px;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='2' viewBox='0 0 12 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.8' d='M0,0 H8 V2 H0 V0 Z' fill='%23A8A8A8'/%3E%3C/svg%3E");
}

.bf-membership-change-content ul > .black,
.bf-membership-change-content ul > .info {
    background-position-y: 0px;
    background-size: 21px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-10 -10 120 120' fill='%234e4e4e'%3E%3Ctitle%3EInfo%3C/title%3E%3Cpath d='M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm.62,75.53a1.48,1.48,0,0,0,1.4,0,13.37,13.37,0,0,0,2.34-2.17c1-1.19,1.88-2.48,2.87-3.82l1.39.84c-2,3.34-4.06,6.44-7.12,8.76a11.06,11.06,0,0,1-5.58,2.33c-4.77.54-7.82-2.71-6.68-7.39,1-4.11,2.27-8.16,3.43-12.24S45,53.52,46.18,49.38a10.35,10.35,0,0,0,.21-1.24,2.45,2.45,0,0,0-2.21-3.06A21.2,21.2,0,0,0,42,45a3.53,3.53,0,0,1,.11-.71l.14-1,17.24-2.76-1.2,4.18h0l-.27,1Q54.27,58.81,50.49,72A8.32,8.32,0,0,0,50,74.14,1.86,1.86,0,0,0,50.62,75.53ZM54.76,31a6.22,6.22,0,0,1-6.18-6.28,6.21,6.21,0,1,1,12.42,0A6.29,6.29,0,0,1,54.76,31Z'/%3E%3C/svg%3E%0A");
}

@layer components {
    body {
        @apply text-anthracite;
        overflow: hidden;
        font-family: 'Heading Pro Double', -apple-system, sans-serif;
        background: #fff;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .proof-of-payment {
        overflow: auto;
        position: initial;
        width: auto;
        height: auto;
        font-size: 11pt;
    }

    .invoice table,
    .invoice th,
    .invoice td {
        border: 1px solid theme('colors.anthracite.DEFAULT');
        border-collapse: collapse;
        padding: 2mm;
    }

    .invoice__table__head > td {
        font-size: 10pt;
    }

    .invoice table.invoice--no-border {
        border-style: hidden;
    }

    .invoice .invoice__club {
        width: 54%;
    }

    .invoice__head td:not(:nth-child(2)) {
        width: 13.5%;
    }

    .invoice__table td:not(:first-child) {
        width: 13.5%;
    }

    .invoice .invoice__table__period td {
        height: 20mm;
    }

    .invoice .invoice__table__nested td {
        width: 50%;
    }

    .mint-top {
        /* Note: feel free to expand for different viewports & use cases */
        @apply lg:border-[60px] lg:p-14;
        border-image: linear-gradient(theme('colors.mint') 125px, transparent 125px) 1;
    }
}
