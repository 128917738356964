/**
 Use grid trick to make a textarea that grows to fit its content.
 https://chriscoyier.net/2023/09/29/css-solves-auto-expanding-textareas-probably-eventually/
 */

.grow-wrap {
  display: grid;
}

.grow-wrap::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}

.grow-wrap>textarea {
  resize: none;
  overflow: hidden;
}

.grow-wrap>textarea,
.grow-wrap::after {
  @apply pl-4;
  @apply pt-l;
  @apply pb-4;
  @apply font-heading-treble;
  grid-area: 1 / 1 / 2 / 2;
}